body {
	font-family: 'Lato', sans-serif;
	background: #23252C;
}
body * {
	box-sizing: border-box;
}

.container {
	width: 350px;
	margin: auto;
	color: white;
	font-size: 16px;
	display: flex;
	align-items: center;
	flex-flow: column;
	padding: 5rem 0;
}
a {
	text-decoration: none;
}
.--bg {
	background-color: #1A1B21;
}
.--txtAlign-center {
	text-align: center;
}
.header,
.content,
.footer {
	width: 100%;
}
.header__image {
	width: 100%;
	display: block;
	border-radius: 10px 10px 0px 0px;
}
.header__info .header-container {
	display: flex;
	flex-flow: column;
	padding: 2rem 0 0 0;
	gap: 0.5rem;
}
.header__info__name {
	font-weight: 900;
	font-size: 2rem;
}
.header__info__profession {
	color: #ff5555;
	font-size: 1.2rem;
	font-weight: 300;
}
.header__info__website a {
	color: white;
	font-size: .9rem;
}
.header__info__website a:hover {
	color: #fea0a0;
}
.header__info__btns {
	display: flex;
	justify-content: center;
	gap: 17px;
	margin-top: 1.6rem;
}
.header__info__btns .btn {
	background-color: white;
	width: 130px;
	height: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 6px;
	font-size: 1rem;
	transition: 500ms;
}
.btn:hover,
.icon:hover {
	transform: scale(1.2);
}
.header__info__btns .btn-email {
	color: black;
}
.header__info__btns .btn-linkedin {
	background: #5093E2;
	color: white;
}
.header__info__btns .btn i {
	font-size: 1.17rem;
	line-height: 0;
}
.content {
	padding: 35px;
	padding-top: 3rem;
}
.content__heading {
	font-size: 1.5rem;
	font-weight: 600;
	padding-bottom: 1rem;
}
.content p {
	margin: 0 0 1rem 0;
	line-height: 150%;
}
.content__interests {
	margin-top: 2rem;
}
.content__interests .interest-list {
	display: flex;
	flex-flow: wrap;
	gap: 1rem;
	align-items: center;
	justify-content: center;
}
.interest-list .list-item {
	background: #ff5555;
	padding: .5rem;
	border-radius: 50rem;
}

/* Re-order items into rows
 * https://tobiasahlin.com/blog/masonry-with-css/
 */
.list-item:nth-child(2n+1) { order: 1; }
.list-item:nth-child(2n+2) { order: 2; }
.list-item:nth-child(2n)   { order: 3; }


.footer {
	background: #161619;
	border-radius: 0px 0px 10px 10px;
	padding: 2rem 1rem;
}
.footer__icons {
	font-size: 2rem;
	display: flex;
	justify-content: center;
	gap: 24px;
}
.footer__icons .icon {
	color: #918E9B;
	transition: 500ms;
}
.icon.icon-twitter {
	color: #1d9bf0;
}
.icon.icon-fb {
	color: #1B74E4;
}
.icon.icon-insta {
	color: #833AB4;
}
.footer__copyright {
	margin-top: 2rem;
}
.footer__react-logo {
	width: 20px;
	margin-top: 1rem;
}
